import * as React from "react"
import Moment from "react-moment";

export default function GameResult({gameData,stat}) {
    console.log(stat);
    console.log(gameData);

    return (
        <>
            <article className="heading-component">
                <div className="heading-component-inner">
                    <h5 className="heading-component-title">Jegyzőkönyv</h5>
                </div>
            </article>

            <div className={'blog-post'}>
                <div className={'row'}>
                    <div className={'col-12 text-center'}>
                        <div className={'badge badge-primary'}>{gameData.championshipName}</div>
                    </div>
                    <div className={'col-12 text-center text-muted'}>
                        {gameData.divisionName}
                    </div>
                    <div className={'col-12 text-center text-muted'}>
                        <Moment format={'YYYY.MM.DD HH:mm'}>{gameData.gameDate}</Moment>&nbsp;
                        <span className="icon mdi mdi-map-marker"/> {gameData.location}
                    </div>
                    {gameData.homeTeamName !== 'x' && gameData.awayTeamName !== 'x' &&
                    <div className={'col-12 pb-5'}>
                        <div className={'row mt-5 mt-lg-0 align-items-center'}>
                            <div className={'col-12 col-lg-3 text-center'}>
                                <img src={gameData.homeTeamLogo} alt={gameData.homeTeamName} className={'w-50'}/>
                                <div className={'game-result-team-name text-uppercase fw-bold'}>{gameData.homeTeamName}</div>
                            </div>
                            <div className={'col-12 py-5 py-lg-0 col-lg-6 text-center'}>
                                {gameData.gameStatus > 0 &&
                                <div className={'row'}>
                                    <div className={'col-12'}>
                                        <div className={`badge ${gameData.gameStatus === 1 ? 'badge-red' : 'bg-secondary'}`}>{gameData.gameStatus === 1 && 'ÉLŐ:'} {gameData.period} {gameData.gameStatus === 1 && gameData.actualTime}</div>
                                    </div>
                                    <div className={'col-12'}>
                                        <div className={'row align-items-center score fw-bold justify-content-center'}>
                                            <div className={'col-auto'}>
                                                <span>{gameData.homeTeamScore}</span>
                                            </div>
                                            <div className={'col-auto semicolon'}>:</div>
                                            <div className={'col-auto'}>
                                                <span>{gameData.awayTeamScore}</span>
                                            </div>
                                        </div>
                                        <div className={'text-muted'}>({
                                            gameData.result && gameData.result.substring(
                                                gameData.result.indexOf("(") + 1,
                                                gameData.result.lastIndexOf(")")
                                            )
                                        })
                                        </div>
                                    </div>
                                </div>}

                                <div className={'row justify-content-center text-muted mt-2'}>
                                    <div className={'col-12 col-md-auto'}>
                                        <label className={'d-block'}>Kapura Lövések</label>
                                        {stat.teamSOG.map(function(period, index){
                                            return period[gameData.awayTeamId].shots !== 0 && period[gameData.homeTeamId].shots !== 0 && <span>{index === 0 ? period[gameData.awayTeamId].shots+':'+period[gameData.homeTeamId].shots : ', '+period[gameData.awayTeamId].shots+':'+period[gameData.homeTeamId].shots}</span>
                                        })}
                                    </div>
                                    <div className={'col-12 col-md-4'}>
                                        <label className={'d-block'}>Nézőszám</label>
                                        {gameData.attendance} fő
                                    </div>
                                    <div className={'col-12 col-md-auto'}>
                                        <label className={'d-block'}>Védések</label>
                                        {stat.teamSOG.map(function(period, index){
                                            return period[gameData.awayTeamId].saves !== 0 && period[gameData.homeTeamId].saves !== 0 && <saves>{index === 0 ? period[gameData.homeTeamId].saves+':'+period[gameData.awayTeamId].saves : ', '+period[gameData.homeTeamId].saves+':'+period[gameData.awayTeamId].saves}</saves>
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className={'col-12 col-lg-3 text-center'}>
                                <img src={gameData.awayTeamLogo} alt={gameData.awayTeamName} className={'w-50'}/>
                                <div className={'game-result-team-name text-uppercase fw-bold'}>{gameData.awayTeamName}</div>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
        </>
    )
}

