import * as React from "react"

export default function Players(props) {
    return (
        <table className="table-custom table-players">
            <thead>
            <tr>
                <th>#</th>
                <th></th>
                <th>Név</th>
                <th>G</th>
                <th>A</th>
                <th>P</th>
                <th>+/-</th>
                <th>Bü</th>
            </tr>
            </thead>
            <tbody>
            {props.players && props.players.map(function(player,index){
                return player.position !== 'GK' && <tr key={index}>
                    <td>
                        {player.number}
                    </td>
                    <td>
                        {player.position}
                    </td>
                    <td>
                        <div className="product-cart-name">
                            {player.lastName} {player.firstName}
                        </div>
                    </td>
                    <td>
                        {player.goal}
                    </td>
                    <td>
                        {player.assist}
                    </td>
                    <td>
                        {player.point}
                    </td>
                    <td>
                        {player.plusMinus}
                    </td>
                    <td>
                        {player.pim}
                    </td>
                </tr>
            })}
            </tbody>
        </table>
    )
}

