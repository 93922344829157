import * as React from "react"

export default function Goalies(props) {
    return (
        <table className="table-custom table-players">
            <thead>
            <tr>
                <th>#</th>
                <th>Név</th>
                <th>TOI</th>
                <th>KL</th>
                <th>GA</th>
                <th>GAA</th>
                <th>VL</th>
                <th>VH%</th>
            </tr>
            </thead>
            <tbody>
            {props.goalies && props.goalies.map(function(player,index){
                return <tr key={index}>
                    <td>
                        {player.jerseyNumber}
                    </td>
                    <td>
                        <div className="product-cart-name">
                            {player.lastName} {player.firstName}
                        </div>
                    </td>
                    <td>
                        {Math.floor(player.mip/60)}:
                        {player.mip%60}
                    </td>
                    <td>
                        {player.sog}
                    </td>
                    <td>
                        {player.ga}
                    </td>
                    <td>
                        {player.gaa}
                    </td>
                    <td>
                        {player.svs}
                    </td>
                    <td>
                        {player.svsPercent} %
                    </td>
                </tr>
            })}
            </tbody>
        </table>
    )
}

