import * as React from "react"
import referee from '../../images/icons/referee.png';
import goalie from '../../images/icons/goalie.png';
import goal from '../../images/icons/goal.png';
import assist from '../../images/icons/assist.png';

export default function GameEvents({gameEvents, players}) {

    function getIcon(type){
        switch (type){
            case 'Kiállítás':
                return <img src={referee} alt={type} width={'30px'}/>
            case 'Kapus':
                return <img src={goalie} alt={type} width={'30px'}/>
            case 'Gól':
                return <img src={goal} alt={type} width={'30px'}/>
        }
    }

    let period = null;

    return (
        <>
            <article className="heading-component mt-5">
                <div className="heading-component-inner">
                    <h5 className="heading-component-title">Események</h5>
                </div>
            </article>

            <div className={'blog-post px-3 py-0'}>
                {gameEvents.map(function(event,index){
                    return <>

                        {event.eventPeriod !== period && <div className={'row align-items-center border-bottom py-2 mt-0 bg-gray-100'} key={index+'--'}>
                                <div className={'col-12 py-0 text-center'}>
                                    {period = event.eventPeriod}
                                </div>
                            </div>
                            }
                            <div className={'row align-items-center border-bottom py-4 mt-0'} key={index}>
                                <div className={'col-3 col-md-2 fw-bold text-muted'}>
                                    <span className="icon mdi mdi-alarm"/> {event.eventTime}
                                </div>
                                <div className={'col-2 col-md-1'}>
                                    <img src={event.teamLogo} width={'40px'} alt={event.teamName}/>
                                </div>
                                <div className={'col-3 col-md-2'}>
                                    {getIcon(event.type)}
                                    <span className={`badge ${event.type === 'Kiállítás' ? 'badge-red ms-1' : 'badge-primary ms-1'}`}>{event.type}</span>
                                    {event.type === 'Kiállítás' && <span className={`badge badge-gray`}>{event.penaltyCause} {event.penaltyLength} perc</span>}
                                </div>
                                <div className={'col-2 col-md-2'}>
                                    <span className={'badge badge-secondary'}>{event.score}</span>
                                    <span className={'badge badge-muted ms-1'}>{event.advantage}</span>
                                </div>
                                <div className={'col-12 pt-3 pt-md-0 col-md-5 fw-bold'}>
                                    <div className={'fs-5'}><span className={'text-muted'}>{event.jerseyNumber ? '#'+event.jerseyNumber : ''}</span> {event.lastName} {event.firstName}</div>
                                    {event.assists1 !== 0 && players[event.assists1] && <div className={'text-muted d-inline-block'}>
                                        <img src={assist} alt="Assist" width={'14px'} className={'me-2'}/>{players[event.assists1].lastName} {players[event.assists1].firstName}</div>}
                                    {event.assists2 !== 0 && players[event.assists2] && <span className={'text-muted d-inline-block'}>, {players[event.assists2].lastName} {players[event.assists2].firstName}</span>}
                                </div>
                            </div>
                        </>

                })}
            </div>
        </>
    )
}

