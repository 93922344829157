import * as React from "react"
import Players from "./players";
import Goalies from "./goalies";

export default function GamePlayers({gameData, gameStat}) {

    return (
        <>
            <div className={'row'}>
                <div className={'col-12 col-md-6'}>
                    <article className="heading-component mt-5">
                        <div className="heading-component-inner">
                            <h5 className="heading-component-title">
                                <div className={'row align-items-center'}>
                                    <div className={'col-auto'}>
                                        <img src={gameData.homeTeamLogo} width={'25px'} alt={gameData.homeTeamName}/>
                                    </div>
                                    <div className={'col-auto'}>
                                        {gameData.homeTeamName}
                                    </div>
                                </div>
                            </h5>
                        </div>
                    </article>

                    {gameStat && gameData.homeTeamName !== 'x' && <Players players={gameStat.players[gameData.homeTeamId]}/>}
                </div>
                <div className={'col-12 col-md-6'}>
                    <article className="heading-component mt-5">
                        <div className="heading-component-inner">
                            <h5 className="heading-component-title">
                                <div className={'row align-items-center'}>
                                    <div className={'col-auto'}>
                                        <img src={gameData.awayTeamLogo} width={'25px'} alt={gameData.awayTeamName}/>
                                    </div>
                                    <div className={'col-auto'}>
                                        {gameData.awayTeamName}
                                    </div>
                                </div>
                            </h5>
                        </div>
                    </article>
                    {gameStat && gameData.awayTeamName !== 'x' && <Players players={gameStat.players[gameData.awayTeamId]}/>}
                </div>
            </div>

            <article className="heading-component mt-5">
                <div className="heading-component-inner">
                    <h5 className="heading-component-title">Kapusok</h5>
                </div>
            </article>

            <div className={'row'}>
                <div className={'col-12 col-md-6'}>

                    <article className="heading-component mt-2 d-lg-none">
                        <div className="heading-component-inner">
                            <h5 className="">
                                <div className={'row align-items-center'}>
                                    <div className={'col-auto'}>
                                        <img src={gameData.homeTeamLogo} width={'25px'} alt={gameData.homeTeamName}/>
                                    </div>
                                    <div className={'col-auto'}>
                                        {gameData.homeTeamName}
                                    </div>
                                </div>
                            </h5>
                        </div>
                    </article>
                    {gameStat && gameData.homeTeamName !== 'x' && <Goalies goalies={gameStat.goalies[gameData.homeTeamId]}/>}
                </div>
                <div className={'col-12 col-md-6'}>

                    <article className="heading-component mt-5 d-lg-none">
                        <div className="heading-component-inner">
                            <h5 className="">
                                <div className={'row align-items-center'}>
                                    <div className={'col-auto'}>
                                        <img src={gameData.awayTeamLogo} width={'25px'} alt={gameData.awayTeamName}/>
                                    </div>
                                    <div className={'col-auto'}>
                                        {gameData.awayTeamName}
                                    </div>
                                </div>
                            </h5>
                        </div>
                    </article>
                    {gameStat && gameData.awayTeamName !== 'x' && <Goalies goalies={gameStat.goalies[gameData.awayTeamId]}/>}
                </div>
            </div>

        </>
    )
}

