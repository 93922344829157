import * as React from "react"
import Layout from "../components/layout";
import {Helmet} from "react-helmet";
import axios from "axios";
import {useEffect, useState} from "react";
import Moment from "react-moment";
import Players from "../components/elements/players";
import Goalies from "../components/elements/goalies";
import IceRinkLive from "../components/elements/iceRinkLive";
import {graphql} from "gatsby";
import Footer from "../components/footer";
import GameEvents from "../components/elements/gameEvents";
import GameResult from "../components/elements/gameResult";
import GamePlayers from "../components/elements/gamePlayers";
import Header from "../components/header";

const MatchPage = ({data, location}) => {

    const partners = data.allContentfulPartners.nodes;
    const teams = data.allContentfulTeam.nodes
    const pages = data.allContentfulPages.nodes

    const [gameData,setGameData] = useState(null);
    const [players,setPlayers] = useState(null);
    const [gameStat,setGameStat] = useState(null);
    const [gameEvents,setGameEvents] = useState(null);
    const [gameId,setGameId] = useState(location.hash.substring(1));


    async function getGame(gameId){
        const APIKEY = '8a1e0c57b1ea70f8063d2874d1c3f670bed22d68';
        const API = 'https://api.icehockey.hu/vbr/v1';

        try {
            axios.get(API+'/gameData', {
                params: {
                    gameId:gameId
                },
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-KEY': APIKEY,
                },
            }).then((response) =>{
                setGameData(response.data.data);
            });
        } catch (error) {
            console.error(error);
        }
    }

    async function getGameStat(gameId){
        const APIKEY = '8a1e0c57b1ea70f8063d2874d1c3f670bed22d68';
        const API = 'https://api.icehockey.hu/vbr/v1';

        try {
            axios.get(API+'/gameStats', {
                params: {
                    gameId:gameId
                },
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-KEY': APIKEY,
                },
            }).then((response) =>{

                const playersByTeam = Object.values(response.data.data.players)

                const playersByApi = []

                playersByTeam.map(function(team){
                    team.map(function(player){
                        playersByApi[player.id] = player;
                    })
                })
                setPlayers(playersByApi)

                setGameStat(response.data.data);
            });
        } catch (error) {
            console.error(error);
        }
    }

    async function getGameEvents(gameId){
        const APIKEY = '8a1e0c57b1ea70f8063d2874d1c3f670bed22d68';
        const API = 'https://api.icehockey.hu/vbr/v1';

        try {
            axios.get(API+'/gameEvents', {
                params: {
                    gameId:gameId
                },
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-KEY': APIKEY,
                },
            }).then((response) =>{
                setGameEvents(response.data.data);
            });
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(()=>{
        getGame(location.hash.substring(1))
        getGameStat(location.hash.substring(1))
        getGameEvents(location.hash.substring(1))
    },[])

    useEffect(()=>{
        setInterval(function(){
            getGame(location.hash.substring(1))
            getGameStat(location.hash.substring(1))
            getGameEvents(location.hash.substring(1))
        },60000);
    },[])

    useEffect(()=>{
        if(location.hash !== '#'+gameId){
            setTimeout(function(){
                window.location.reload()
            },200)
        }
    },[location.hash])


    return (
        <Layout>
            <Helmet>
                <title>Mérkőzés adatlap - Zempléni Hiúzok</title>
            </Helmet>
            <Header pages={pages} teams={teams}/>
            <main>
                <section className="section section-md bg-gray-100 gameResult">
                    <div className="container">
                        <div className="row row-50">
                            <div className={'col-12'}>
                                {gameData && <div className={'gameData'}>
                                    <GameResult gameData={gameData} stat={gameStat}/>

                                    {gameData.gameStatus === 1 && gameData.location === 'Sátoraljaújhelyi Jégcsarnok' &&
                                        <div className={'mt-5'}>
                                            <IceRinkLive/>
                                        </div>
                                    }

                                    {gameEvents && gameEvents.length !== 0 && <GameEvents players={players} gameEvents={gameEvents}/>}
                                    <GamePlayers gameData={gameData} gameStat={gameStat}/>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer partners={partners}/>
        </Layout>
    )
}

export default MatchPage

export const query = graphql`
  query getPartners {
  allContentfulPartners {
    nodes {
      title
      logo {
        url
      }
    }
  }
  allContentfulPages {
    nodes {
      text {
        raw
      }
      title
      slug
    }
  }
  allContentfulTeam(sort: {fields: title, order: ASC}) {
    nodes {
      title
      label
    }
  }
}
`
